function handleErrorsForm(errors) {
    $.each(errors, function (i, e) {
        let field = $('[name=' + i + ']');
        field.addClass('is-invalid');

        console.log(i , e);
        if (e.length === 1) {
            field.siblings('[data-message]').html(e[0]);
        }
    });
}

jQuery(function ($) {
    let lang = $('meta[name="lang"]').attr('content');

    if (lang === 'ru') {
        $('input[name="phone"]').mask('+7 (999) 999-99-99');
    }


    $('#form-feedback').submit(function (e) {
        e.preventDefault();
        let _this = $(this);
        let message = $('#feedback-message');
        let fields = _this.find("input, textarea");
        let messages = _this.find('[data-message]');
        let btn = _this.find('button');

        message.html('');
        messages.html('');
        fields.removeClass('is-invalid');
        btn.attr('disabled', true);

        $.post(_this.attr('action'), _this.serializeArray())
            .done(function (data) {
                fields.val('');
                message.html(data);
            })
            .fail(function (jqXHR) {
                handleErrorsForm(jqXHR.responseJSON.errors)
            })
            .always(function () {
                btn.attr('disabled', false);
            });
    });
});
