jQuery(function ($) {
    let formSubscribeMessage = $('#form-subscribe-msg');

    $('#form-subscribe').submit(function (e) {
        e.preventDefault();
        formSubscribeMessage.removeClass('text-success text-danger').empty();

        $.post($(this).attr('action'), $(this).serializeArray())
            .done(function (data) {
                formSubscribeMessage.addClass('text-success').text(data.message).show();
                $('#form-subscribe input[name="email"]').val('');
            })
            .fail(function () {
                formSubscribeMessage.addClass('text-danger').text('Произошла ошибка. Попробуйте снова.').show();
            });
    });
});
