$(document).ready(function(){
    $(window).scroll(function(){
        menuSetup($(this));
    });
});

$(window).on('load', function () {
    menuSetup($(this));
});

function menuSetup(window) {
    let scrolling = $(".site-header");
    let height = 25;

    if ( window.scrollTop() >= height && scrolling.hasClass("loading") ){
        scrolling.removeClass("loading").addClass("scrolling scroll");
    } else if(window.scrollTop() <= height && scrolling.hasClass("scrolling")) {
        scrolling.removeClass("scrolling scroll").addClass("loading");
    }
}
